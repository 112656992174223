import React from 'react';
import { useLocation } from 'react-router-dom';
import ResultsReport from '../components/ResultsReport';
import ParticipationInfo from '../components/ParticipationInfo';
import SurveyResults from '../components/SurveyResults';
import ScreeningStatus from '../components/ScreeningStatus';
import '../styles/contentPage.css';

const ContentPage = () => {
  const location = useLocation();

  const renderComponent = () => {
    switch (location.pathname) {
      case '/kdst-results':
        return <ResultsReport />;
      case '/participation-info':
        return <ParticipationInfo />;
      case '/raw-data':
        return <SurveyResults />;
      case '/screening-status':
      case '/':
        return <ScreeningStatus />;
      default:
        return <div>Page not found</div>;
    }
  };

  return (
    <div className="content-page">
      {renderComponent()}
    </div>
  );
};

export default ContentPage;