import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import "../styles/participationInfo.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ParticipationInfo = () => {
  const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [newInstitution, setNewInstitution] = useState({
    region: "",
    institution_type: "",
    institution_name: "",
    auth_code: "",
  });

  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'ascending' });
  const [filters, setFilters] = useState({
    region: "",
    institution_type: "",
    institution_name: "",
  });

  useEffect(() => {
    fetchInstitutions();
  }, []);

  const fetchInstitutions = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/institutions/all`);
      setInstitutions(response.data);
      setLoading(false);
    } catch (err) {
      setError("데이터를 불러오는 데 실패했습니다.");
      setLoading(false);
    }
  };

  const handleCreate = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/institutions`, newInstitution);
      fetchInstitutions();
      setNewInstitution({
        region: "",
        institution_type: "",
        institution_name: "",
        auth_code: "",
      });
    } catch (err) {
      setError("기관 추가에 실패했습니다.");
    }
  };

  const handleUpdate = async (id, updatedData) => {
    try {
      await axios.put(`${API_BASE_URL}/api/institutions/${id}`, updatedData);
      fetchInstitutions();
      setEditingId(null);
    } catch (err) {
      setError("기관 정보 수정에 실패했습니다.");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("정말로 이 기관을 삭제하시겠습니까?")) {
      try {
        await axios.delete(`${API_BASE_URL}/api/institutions/${id}`);
        fetchInstitutions();
      } catch (err) {
        setError("기관 삭제에 실패했습니다.");
      }
    }
  };

  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(institutions);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Institutions");
    XLSX.writeFile(workbook, "institutions.xlsx");
  };

  const sortedInstitutions = institutions.sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const filteredInstitutions = sortedInstitutions.filter(institution => {
    return (
      (filters.region === "" || institution.region.includes(filters.region)) &&
      (filters.institution_type === "" || institution.institution_type.includes(filters.institution_type)) &&
      (filters.institution_name === "" || institution.institution_name.includes(filters.institution_name))
    );
  });

  const requestSort = key => {
    let direction = 'ascending';
    if (
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  if (loading) return <div>로딩 중...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="participation-info">
      <h2>참여 기관 정보 및 인증번호 리스트</h2>

      <div className="add-institution-form">
        <input
          type="text"
          placeholder="지역"
          value={newInstitution.region}
          onChange={(e) =>
            setNewInstitution({ ...newInstitution, region: e.target.value })
          }
        />
        <input
          type="text"
          placeholder="기관 유형"
          value={newInstitution.institution_type}
          onChange={(e) =>
            setNewInstitution({
              ...newInstitution,
              institution_type: e.target.value,
            })
          }
        />
        <input
          type="text"
          placeholder="기관명"
          value={newInstitution.institution_name}
          onChange={(e) =>
            setNewInstitution({
              ...newInstitution,
              institution_name: e.target.value,
            })
          }
        />
        <input
          type="text"
          placeholder="인증 코드"
          value={newInstitution.auth_code}
          onChange={(e) =>
            setNewInstitution({ ...newInstitution, auth_code: e.target.value })
          }
        />
        <button onClick={handleCreate}>추가</button>
        <button onClick={handleDownloadExcel}>엑셀로 다운로드</button>
      </div>

      <div className="filters">
        <input
          type="text"
          placeholder="지역 필터"
          value={filters.region}
          onChange={(e) =>
            setFilters({ ...filters, region: e.target.value })
          }
        />
        <input
          type="text"
          placeholder="기관 유형 필터"
          value={filters.institution_type}
          onChange={(e) =>
            setFilters({ ...filters, institution_type: e.target.value })
          }
        />
        <input
          type="text"
          placeholder="기관명 필터"
          value={filters.institution_name}
          onChange={(e) =>
            setFilters({ ...filters, institution_name: e.target.value })
          }
        />
      </div>

      <table>
        <thead>
          <tr>
            <th onClick={() => requestSort('id')}>ID</th>
            <th onClick={() => requestSort('region')}>지역</th>
            <th onClick={() => requestSort('institution_type')}>기관 유형</th>
            <th onClick={() => requestSort('institution_name')}>기관명</th>
            <th>인증 코드</th>
            <th>작업</th>
          </tr>
        </thead>
        <tbody>
          {filteredInstitutions.map((institution) => (
            <tr key={institution.id}>
              <td>{institution.id}</td>
              <td>
                {editingId === institution.id ? (
                  <input
                    type="text"
                    value={institution.region}
                    onChange={(e) =>
                      handleUpdate(institution.id, {
                        ...institution,
                        region: e.target.value,
                      })
                    }
                  />
                ) : (
                  institution.region
                )}
              </td>
              <td>
                {editingId === institution.id ? (
                  <input
                    type="text"
                    value={institution.institution_type}
                    onChange={(e) =>
                      handleUpdate(institution.id, {
                        ...institution,
                        institution_type: e.target.value,
                      })
                    }
                  />
                ) : (
                  institution.institution_type
                )}
              </td>
              <td>
                {editingId === institution.id ? (
                  <input
                    type="text"
                    value={institution.institution_name}
                    onChange={(e) =>
                      handleUpdate(institution.id, {
                        ...institution,
                        institution_name: e.target.value,
                      })
                    }
                  />
                ) : (
                  institution.institution_name
                )}
              </td>
              <td>
                {editingId === institution.id ? (
                  <input
                    type="text"
                    value={institution.auth_code}
                    onChange={(e) =>
                      handleUpdate(institution.id, {
                        ...institution,
                        auth_code: e.target.value,
                      })
                    }
                  />
                ) : (
                  institution.auth_code
                )}
              </td>
              <td>
                <div className="action-buttons">
                  {editingId === institution.id ? (
                    <button
                      onClick={() => handleUpdate(institution.id, institution)}
                    >
                      저장
                    </button>
                  ) : (
                    <>
                      <button onClick={() => setEditingId(institution.id)}>
                        수정
                      </button>
                      <button
                        className="delete"
                        onClick={() => handleDelete(institution.id)}
                      >
                        삭제
                      </button>
                    </>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ParticipationInfo;
