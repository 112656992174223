import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import "../styles/login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const success = await login(username, password);
      if (success) {
        navigate("/");
      } else {
        alert("로그인에 실패했습니다. 사용자 이름과 비밀번호를 확인해주세요.");
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("로그인 중 오류가 발생했습니다. 나중에 다시 시도해주세요.");
    }
  };

  // 로그아웃 함수와 창 닫기 이벤트 리스너는 제거합니다.
  // 이 기능들은 AuthContext에서 전역적으로 관리하는 것이 좋습니다.

  return (
    <div className="login-container">
      <div className="login-content">
        <h1 className="login-title">
          유아 언어발달 검사 및 치료지원 사업 관리자페이지
        </h1>
        <form onSubmit={handleSubmit} className="login-form">
          <h2>Login</h2>
          <div className="form-group">
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;