import React from 'react';
import { useParams } from 'react-router-dom';
import StepReport from '../components/StepReport';
import '../styles/reportPage.css';

const ReportPage = () => {
  const { id } = useParams();

  return (
    <div className="report-page">
      <StepReport id={id} />
    </div>
  );
};

export default ReportPage;
