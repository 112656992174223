import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import "../styles/surveyResults.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SurveyResults = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [filters, setFilters] = useState({});

  useEffect(() => {
    fetchResults();
  }, []);

  const fetchResults = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/survey/results`);
      const sortedResults = response.data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      setResults(sortedResults);
      setLoading(false);
    } catch (err) {
      setError("데이터를 불러오는 데 실패했습니다.");
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("정말로 이 설문을 삭제하시겠습니까?")) {
      try {
        await axios.delete(`${API_BASE_URL}/api/survey/results/${id}`);
        fetchResults();
      } catch (err) {
        setError("데이터 삭제에 실패했습니다.");
      }
    }
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleFilter = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const sortedResults = React.useMemo(() => {
    let sortableResults = [...results];
    if (sortConfig.key !== null) {
      sortableResults.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableResults;
  }, [results, sortConfig]);

  const filteredResults = React.useMemo(() => {
    return sortedResults.filter((result) => {
      return Object.entries(filters).every(([key, value]) => {
        return result[key].toLowerCase().includes(value.toLowerCase());
      });
    });
  }, [sortedResults, filters]);

  const exportToExcel = () => {
    const dataToExport = filteredResults.map((result, index) => ({
      연번: index + 1,
      ID: result.id,
      권역: result.region,
      지역: result.area,
      기관유형: result.institution_type,
      기관명: result.institution_name,
      아동명: result.child_name,
      성별: result.gender,
      월령: result.age_in_months,
      주양육자: result.primary_caregiver,
      어머니_학력: result.mother_education,
      아버지_학력: result.father_education,
      소득분위: result.income_level,
      작성자유형: result.writer_type,
      검사일: new Date(result.created_at).toLocaleString("ko-KR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }),
      대근육_원점수: result.gross_motor_sum_score,
      대근육_결과해석: result.gross_motor_result,
      소근육_원점수: result.fine_motor_sum_score,
      소근육_결과해석: result.fine_motor_result,
      인지_원점수: result.cognitive_sum_score,
      인지_결과해석: result.cognitive_result,
      언어_원점수: result.language_sum_score,
      언어_결과해석: result.language_result,
      사회성_원점수: result.social_sum_score,
      사회성_결과해석: result.social_result,
      자조_원점수: result.selfcare_sum_score,
      자조_결과해석: result.selfcare_result,
      추가_원점수: result.addition_sum_score,
      K_DST_선택항목: result.selected_survey_options,
      "34세_총점": result.age_34_total_score,
      "34세_의사소통": result.age_34_communication,
      "34세_이해": result.age_34_comprehension,
      "34세_표현": result.age_34_expression,
      "34세_발음유창성": result.age_34_fluency,
      "5세_총점": result.age_5_total_score,
      "5세_이해표현": result.age_5_comprehension_expression,
      "5세_말문해": result.age_5_sentence,
      체크리스트_선택항목: result.selected_checklist_options,
    }));


    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Survey Results");
    XLSX.writeFile(workbook, "survey_results.xlsx");
  };

  if (loading) return <div>로딩 중...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="survey-results">
      <h2>설문 결과 리스트</h2>
      <div className="filters-and-export-container">
        <div className="filters-and-export">
          <div className="filters">
            <input
              type="text"
              placeholder="지역으로 필터"
              onChange={(e) => handleFilter("region", e.target.value)}
            />
            <input
              type="text"
              placeholder="권역으로 필터"
              onChange={(e) => handleFilter("area", e.target.value)}
            />
            <input
              type="text"
              placeholder="기관명으로 필터"
              onChange={(e) => handleFilter("institution_name", e.target.value)}
            />
            <input
              type="text"
              placeholder="아동명으로 필터"
              onChange={(e) => handleFilter("child_name", e.target.value)}
            />
          </div>
          <button className="export-button" onClick={exportToExcel}>
            엑셀로 내보내기
          </button>
        </div>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>연번</th>
              <th onClick={() => handleSort("id")}>ID</th>
              <th onClick={() => handleSort("region")}>지역</th>
              <th onClick={() => handleSort("area")}>권역</th>
              <th onClick={() => handleSort("institution_type")}>기관유형</th>
              <th onClick={() => handleSort("institution_name")}>기관명</th>
              <th onClick={() => handleSort("child_name")}>아동명</th>
              <th onClick={() => handleSort("gender")}>성별</th>
              <th onClick={() => handleSort("age_in_months")}>월령</th>
              <th onClick={() => handleSort("primary_caregiver")}>주양육자</th>
              <th onClick={() => handleSort("mother_education")}>어머니_학력</th>
              <th onClick={() => handleSort("father_education")}>아버지_학력</th>
              <th onClick={() => handleSort("income_level")}>소득분위</th>
              <th onClick={() => handleSort("writer_type")}>작성자유형</th>
              <th onClick={() => handleSort("created_at")}>검사일</th>
              <th onClick={() => handleSort("gross_motor_sum_score")}>
                대근육_원점수
              </th>
              <th onClick={() => handleSort("gross_motor_result")}>
                대근육_결과해석
              </th>
              <th onClick={() => handleSort("fine_motor_sum_score")}>
                소근육_원점수
              </th>
              <th onClick={() => handleSort("fine_motor_result")}>
                소근육_결과해석
              </th>
              <th onClick={() => handleSort("cognitive_sum_score")}>
                인지_원점수
              </th>
              <th onClick={() => handleSort("cognitive_result")}>
                인지_결과해석
              </th>
              <th onClick={() => handleSort("language_sum_score")}>
                언어_원점수
              </th>
              <th onClick={() => handleSort("language_result")}>
                언어_결과해석
              </th>
              <th onClick={() => handleSort("social_sum_score")}>
                사회성_원점수
              </th>
              <th onClick={() => handleSort("social_result")}>
                사회성_결과해석
              </th>
              <th onClick={() => handleSort("selfcare_sum_score")}>
                자조_원점수
              </th>
              <th onClick={() => handleSort("selfcare_result")}>
                자조_결과해석
              </th>
              <th onClick={() => handleSort("addition_sum_score")}>
                추가_원점수
              </th>
              <th onClick={() => handleSort("selected_survey_options")}>
                K-DST_선택항목
              </th>
              <th onClick={() => handleSort("age_34_total_score")}>
                34세_총점
              </th>
              <th onClick={() => handleSort("age_34_communication")}>
                34세_의사소통
              </th>
              <th onClick={() => handleSort("age_34_comprehension")}>
                34세_이해
              </th>
              <th onClick={() => handleSort("age_34_expression")}>34세_표현</th>
              <th onClick={() => handleSort("age_34_fluency")}>
                34세_발음유창성
              </th>
              <th onClick={() => handleSort("age_5_total_score")}>5세_총점</th>
              <th onClick={() => handleSort("age_5_comprehension_expression")}>
                5세_이해표현
              </th>
              <th onClick={() => handleSort("age_5_sentence")}>5세_말문해</th>
              <th onClick={() => handleSort("selected_checklist_options")}>
                체크리스트_선택항목
              </th>
              <th>작업</th>
            </tr>
          </thead>
          <tbody>
            {filteredResults.map((result, index) => (
              <tr key={result.id}>
                <td>{index + 1}</td>
                <td>{result.id}</td>
                <td>{result.region}</td>
                <td>{result.area}</td>
                <td>{result.institution_type}</td>
                <td>{result.institution_name}</td>
                <td>{result.child_name}</td>
                <td>{result.gender}</td>
                <td>{result.age_in_months}</td>
                <td>{result.primary_caregiver}</td>
                <td>{result.mother_education}</td>
                <td>{result.father_education}</td>
                <td>{result.income_level}</td>
                <td>{result.writer_type}</td>
                <td>
                  {new Date(result.created_at).toLocaleString("ko-KR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </td>
                <td>{result.gross_motor_sum_score}</td>
                <td>{result.gross_motor_result}</td>
                <td>{result.fine_motor_sum_score}</td>
                <td>{result.fine_motor_result}</td>
                <td>{result.cognitive_sum_score}</td>
                <td>{result.cognitive_result}</td>
                <td>{result.language_sum_score}</td>
                <td>{result.language_result}</td>
                <td>{result.social_sum_score}</td>
                <td>{result.social_result}</td>
                <td>{result.selfcare_sum_score}</td>
                <td>{result.selfcare_result}</td>
                <td>{result.addition_sum_score}</td>
                <td>{result.selected_survey_options}</td>
                <td>{result.age_34_total_score}</td>
                <td>{result.age_34_communication}</td>
                <td>{result.age_34_comprehension}</td>
                <td>{result.age_34_expression}</td>
                <td>{result.age_34_fluency}</td>
                <td>{result.age_5_total_score}</td>
                <td>{result.age_5_comprehension_expression}</td>
                <td>{result.age_5_sentence}</td>
                <td>{result.selected_checklist_options}</td>
                <td>
                  <div className="action-buttons">
                    <button onClick={() => handleDelete(result.id)}>삭제</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SurveyResults;
