import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/screeningStatus.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const sampleData = {
  "영서1": {
    "춘천": { "유치원": 2875, "어린이집": 2180, "전체": 5055 },
    "인제": { "유치원": 239, "어린이집": 378, "전체": 617 },
    "양구": { "유치원": 127, "어린이집": 252, "전체": 379 },
    "화천": { "유치원": 217, "어린이집": 177, "전체": 1012 },
    "철원": { "유치원": 521, "어린이집": 491, "전체": 1012 },
    "소계": { "유치원": 3979, "어린이집": 3478, "전체": 8075 }
  },
  "영서2/영서": {
    "원주": { "유치원": 4217, "어린이집": 2504, "전체": 6721 },
    "횡성": { "유치원": 194, "어린이집": 291, "전체": 485 },
    "평창": { "유치원": 146, "어린이집": 212, "전체": 358 },
    "홍천": { "유치원": 394, "어린이집": 438, "전체": 832 },
    "소계": { "유치원": 4951, "어린이집": 3445, "전체": 8396 }
  },
  "영동": {
    "강릉": { "유치원": 1435, "어린이집": 1614, "전체": 3049 },
    "동해": { "유치원": 717, "어린이집": 712, "전체": 1429 },
    "속초/양양": { "유치원": 496, "어린이집": 1092, "전체": 1588 },
    "고성": { "유치원": 105, "어린이집": 232, "전체": 337 },
    "소계": { "유치원": 2753, "어린이집": 3650, "전체": 6403 }
  },
  "영동2/남부": {
    "영월": { "유치원": 107, "어린이집": 167, "전체": 274 },
    "정선": { "유치원": 153, "어린이집": 190, "전체": 343 },
    "태백": { "유치원": 142, "어린이집": 360, "전체": 502 },
    "삼척": { "유치원": 563, "어린이집": 261, "전체": 824 },
    "소계": { "유치원": 965, "어린이집": 978, "전체": 1943 }
  },
  "합계": { "유치원": 12648, "어린이집": 11551, "전체": 24817 }
};

const areaMapping = {
  "영서1": "영서1",
  "영서2": "영서2/영서",
  "영동1": "영동",
  "영동2": "영동2/남부"
};

const ScreeningStatus = () => {
  const [surveyResults, setSurveyResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/survey/results`);
        setSurveyResults(response.data);
        setLoading(false);
      } catch (err) {
        setError("데이터를 불러오는 데 실패했습니다.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getParticipantCount = (area, region, institutionType = null) => {
    return surveyResults.filter(result => {
      const mappedArea = areaMapping[result.area] || result.area;
      const regionMatch = region === "속초/양양" 
        ? (result.region === "속초" || result.region === "양양")
        : result.region === region;
      
      const areaMatch = mappedArea === area;
      const typeMatch = institutionType ? result.institution_type === institutionType : true;
      
      return regionMatch && areaMatch && typeMatch;
    }).length;
  };

  const renderTable = () => {
    let totalKindergarten = 0;
    let totalDaycare = 0;
    let totalParticipants = 0;
    let totalSampleKindergarten = 0;
    let totalSampleDaycare = 0;
    let totalSample = 0;

    return (
      <table>
        <thead>
          <tr>
            <th rowSpan="3">권역</th>
            <th rowSpan="3">지역</th>
            <th colSpan="3" rowSpan="2">강원도 표본수(3, 4, 5세)</th>
            <th colSpan="6">참여유아수</th>
          </tr>
          <tr>
            <th colSpan="2">유치원</th>
            <th colSpan="2">어린이집</th>
            <th colSpan="2">전체</th>
          </tr>
          <tr>
            <th rowSpan="1">유치원</th>
            <th rowSpan="1">어린이집</th>
            <th rowSpan="1">전체</th>
            <th>사례수</th>
            <th>비율</th>
            <th>사례수</th>
            <th>비율</th>
            <th>사례수</th>
            <th>비율</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(sampleData).map(([area, regions]) => 
            Object.entries(regions).map(([region, data], index) => {
              if (region === "소계" || area === "합계") return null;
              const kindergartenCount = getParticipantCount(area, region, "유치원");
              const daycareCount = getParticipantCount(area, region, "어린이집");
              const totalCount = kindergartenCount + daycareCount;

              totalKindergarten += kindergartenCount;
              totalDaycare += daycareCount;
              totalParticipants += totalCount;
              totalSampleKindergarten += data.유치원;
              totalSampleDaycare += data.어린이집;
              totalSample += data.전체;

              return (
                <tr key={`${area}-${region}`}>
                  {index === 0 && <td  className='region-table' rowSpan={Object.keys(regions).length - 1}>{area}</td>}
                  <td>{region}</td>
                  <td>{data.유치원}</td>
                  <td>{data.어린이집}</td>
                  <td>{data.전체}</td>
                  <td>{kindergartenCount}</td>
                  <td>{((kindergartenCount / data.유치원) * 100).toFixed(2)}%</td>
                  <td>{daycareCount}</td>
                  <td>{((daycareCount / data.어린이집) * 100).toFixed(2)}%</td>
                  <td>{totalCount}</td>
                  <td>{((totalCount / data.전체) * 100).toFixed(2)}%</td>
                </tr>
              );
            })
          )}
          <tr className="col-sum">
            <td  colSpan="2">합계</td>
            <td>{totalSampleKindergarten}</td>
            <td>{totalSampleDaycare}</td>
            <td>{totalSample}</td>
            <td>{totalKindergarten}</td>
            <td>{((totalKindergarten / totalSampleKindergarten) * 100).toFixed(2)}%</td>
            <td>{totalDaycare}</td>
            <td>{((totalDaycare / totalSampleDaycare) * 100).toFixed(2)}%</td>
            <td>{totalParticipants}</td>
            <td>{((totalParticipants / totalSample) * 100).toFixed(2)}%</td>
          </tr>
        </tbody>
      </table>
    );
  };

  if (loading) return <div>로딩 중...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="screening-status">
      <h2>선별 검사 참여 현황</h2>
      <h3>권역/지역별 참여 유아 현황</h3>
      {renderTable()}
    </div>
  );
};

export default ScreeningStatus;