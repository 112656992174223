import React from "react";
import { NavLink } from "react-router-dom";
import '../styles/sidebar.css';

const Sidebar = () => {
  return (
    <nav className="sidebar">
      <ul>
        <li>
          <NavLink to="/screening-status" activeclassname="active">선별 검사 참여 현황</NavLink>
        </li>
        <li>
          <NavLink to="/participation-info" activeclassname="active">참여 기관 정보</NavLink>
        </li>
        <li>
          <NavLink to="/raw-data" activeclassname="active">검사 결과 raw data</NavLink>
        </li>
        <li>
          <NavLink to="/kdst-results" activeclassname="active">결과보고서</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
