import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import '../styles/resultsReport.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ResultsReport = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filters, setFilters] = useState({
    region: '',
    area: '',
    institution_name: '',
    child_name: ''
  });

  useEffect(() => {
    fetchResults();
  }, []);

  const fetchResults = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/survey/results`);
      setResults(response.data);
      setLoading(false);
    } catch (err) {
      setError("데이터를 불러오는 데 실패했습니다.");
      setLoading(false);
    }
  };

  const handlePrint = (id) => {
    const newWindow = window.open(`/report/${id}`, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleFilter = (key, value) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const sortedResults = React.useMemo(() => {
    let sortableResults = [...results];
    if (sortConfig.key !== null) {
      sortableResults.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableResults;
  }, [results, sortConfig]);

  const filteredResults = React.useMemo(() => {
    return sortedResults.filter(result => {
      return Object.entries(filters).every(([key, value]) => {
        return result[key].toLowerCase().includes(value.toLowerCase());
      });
    });
  }, [sortedResults, filters]);

  const exportToExcel = () => {
    const dataToExport = filteredResults.map((result, index) => ({
      번호: index + 1,
      권역: result.region,
      지역: result.area,
      기관명: result.institution_name,
      아동명: result.child_name,
      월령: result.age_in_months
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Results Report");
    XLSX.writeFile(workbook, "results_report.xlsx");
  };

  if (loading) return <div>로딩 중...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="results-report">
      <h2>결과보고서</h2>
      <div className="filters-and-export">
        <input
          type="text"
          placeholder="지역으로 필터"
          onChange={(e) => handleFilter('region', e.target.value)}
        />
        <input
          type="text"
          placeholder="권역으로 필터"
          onChange={(e) => handleFilter('area', e.target.value)}
        />
        <input
          type="text"
          placeholder="기관명으로 필터"
          onChange={(e) => handleFilter('institution_name', e.target.value)}
        />
        <input
          type="text"
          placeholder="아동명으로 필터"
          onChange={(e) => handleFilter('child_name', e.target.value)}
        />
        <button className="export-button" onClick={exportToExcel}>
          엑셀로 내보내기
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th onClick={() => handleSort('id')}>번호</th>
            <th onClick={() => handleSort('region')}>지역</th>
            <th onClick={() => handleSort('area')}>권역</th>
            <th onClick={() => handleSort('institution_name')}>기관명</th>
            <th onClick={() => handleSort('child_name')}>아동명</th>
            <th onClick={() => handleSort('age_in_months')}>월령</th>
            <th>결과보고서 출력</th>
          </tr>
        </thead>
        <tbody>
          {filteredResults.map((result, index) => (
            <tr key={result.id}>
              <td>{index + 1}</td>
              <td>{result.region}</td>
              <td>{result.area}</td>
              <td>{result.institution_name}</td>
              <td>{result.child_name}</td>
              <td>{result.age_in_months}</td>
              <td>
                <div className="action-buttons">
                  <button onClick={() => handlePrint(result.id)}>출력</button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResultsReport;